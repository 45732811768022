/* eslint-disable import/prefer-default-export */
import './scss/style.scss';

import { start } from 'single-spa';
import layout from './js/layout';
import registerEvents from './js/events';
import debugging from './js/debugging';
import { setEnvContext } from './js/envContext';
import registerCookieBot from './js/cookiebot';
import setUpLogging from './js/logging';
import setUpEnvContextConfig from './js/envContextConfig';

/**
 * Checks whether maintenance-mode is on
 */
const isMaintenance = process.env.MAINTENANCE_MODE === 'true';

export async function init() {
  setEnvContext();
  setUpLogging();
  setUpEnvContextConfig();

  await System.import('@peakwork/peakwork-set-utilities');
  System.import('@peakwork/peakwork-set-account')
    .then(async (account) => {
      const url = window.location.href;
      if (
        (
          url
          && (
            !url.includes('account/login')
            && !url.includes('account/keycloak/handover')
          )
        )
      ) {
        account?.saveLoginRedirectPath?.(url);
      }

      if (isMaintenance) {
        if (
          typeof account.userIsLoggedIn === 'function'
          && account.userIsLoggedIn()
          && typeof account.logout === 'function'
        ) {
          await account.logout();
        }
        window.location.replace('/maintenance.html');
        return;
      }
      if (account.init) {
        await account.init();
      }
      debugging();
      layout(account);
      registerEvents(account);
      start({
        urlRerouteOnly: true,
      });
      registerCookieBot();
    });
}
init();
