import { getEnvContext } from './envContext';

const titles = {
  peakworkSet: 'Peakwork Set',
  sabreSet: 'Sabre Vacations',
};

const favIcons = {
  peakworkSet: '/images/favicon-16x16.png',
  sabreSet: '/images/faviconSabre-16x16.png',
};

const variables = {
  peakworkSet: {
    '--q-primary': '#00415a',
    '--q-color-primary': '#00415a',
    '--q-secondary': '#009cd2',
    '--q-color-secondary': '#009cd2',
    '--q-accent': '#f0d214',
    '--q-color-accent': '#f0d214',
    '--q-positive': '#3ab14c',
    '--q-color-positive': '#3ab14c',
    '--q-negative': '#d62341',
    '--q-color-negative': '#d62341',
    '--q-info': '#31ccec',
    '--q-color-info': '#31ccec',
    '--q-warning': '#f2c037',
    '--q-color-warning': '#f2c037',
    '--main-bg-color': '#d3dee2',
    '--main-text-color': '#4c4c4c',
    '--main-logo-url': '/images/logo@2x.svg',
  },
  sabreSet: {
    '--q-primary': '#e50000',
    '--q-color-primary': '#e50000',
    '--q-secondary': '#009465',
    '--q-color-secondary': '#009465',
    '--q-accent': '#b3b3b3',
    '--q-color-accent': '#b3b3b3',
    '--q-positive': '#008247',
    '--q-color-positive': '#008247',
    '--q-negative': '#d13630',
    '--q-color-negative': '#d13630',
    '--q-info': '#007b94',
    '--q-color-info': '#007b94',
    '--q-warning': '#f7b660',
    '--q-color-warning': '#f7b660',
    '--main-bg-color': '#f6f6f7',
    '--main-text-color': '#121315',
    '--main-logo-url': '/images/sabre-logo-small.png',
  },
};

function setTitle(title) {
  document.title = title;
}

function setFavIcon(icon) {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = icon;
}

function setDefaultVariables(vars) {
  Object.entries(vars).forEach(([varName, varValue]) => {
    document.body.style.setProperty(varName, varValue);
  });
}

export default function setUpEnvContextConfig() {
  const envContext = getEnvContext();
  setTitle(titles[envContext]);
  setFavIcon(favIcons[envContext]);
  setDefaultVariables(variables[envContext]);
}
