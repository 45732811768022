import { getEnvContext } from './envContext';

export default function registerCookieBot() {
  if (process.env.ENABLE_CONSENT_COOKIEBOT === 'true') {
    const peakworkCbid = process.env.COOKIEBOT_CBID_PEAKWORK;
    const SabreCbid = process.env.COOKIEBOT_CBID_SABRE;
    const cbid = getEnvContext() === 'peakworkSet' ? peakworkCbid : SabreCbid;
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('id', 'Cookiebot');
    scriptEle.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
    scriptEle.setAttribute('type', 'text/javascript');
    scriptEle.setAttribute('data-cbid', cbid);
    scriptEle.setAttribute('data-blockingmode', 'none');
    document.body.appendChild(scriptEle);
  }
}
