import { registerApplication } from 'single-spa';
import {
  constructApplications, constructRoutes, constructLayoutEngine,
} from 'single-spa-layout';

/**
 * filter the routes from any envContext specific routes
 * it could be used by adding envContext: 'environment', to any route object
 * @param {*} routes Array of routes
 * @returns new routes
 */
function filterRoutes(routes) {
  return routes.reduce((enabledRoutes, route) => {
    const { envContextList = false, skip = false, ...rest } = route;

    const isIncluded = !envContextList || (envContextList.includes(window.PEAKWORK_SET.envContext));
    if (!skip && isIncluded) {
      enabledRoutes.push({
        ...rest,
        ...(rest.routes && {
          routes: filterRoutes(rest.routes),
        }),
      });
    }

    return enabledRoutes;
  }, []);
}

function getRoutes(account, props) {
  if (
    !props.isLoggedIn
    || window.location.pathname.startsWith('/public/')
  ) {
    return filterRoutes([
      {
        type: 'main',
        routes: [
          // add all public account definitions
          ...[
            'account',
            'public/account',
          ].map((path) => ({
            type: 'route',
            path,
            routes: [
              {
                type: 'application',
                name: '@peakwork/peakwork-set-account',
              },
            ],
          })),
        ],
      },
      {
        type: 'footer',
        routes: [
          {
            type: 'application',
            name: '@peakwork/peakwork-set-footer',
            props,
          },
        ],
        attrs: [
          {
            name: 'style',
            value: 'position: fixed; bottom: 0; left: 0; right: 0;',
          },
        ],
        envContextList: ['sabreSet'],
      },
    ]);
  }

  const {
    FEATURES_MAPPING,
    isFeatureEnabled,
    GLOBAL_FEATURES_MAPPING,
    isGlobalFeatureEnabled,
  } = account;
  const {
    isAdministrator = false,
    hasSystemAccessPermissions = false,
  } = account?.getUserSettings?.() ?? {};

  const staticContentRoutePaths = [
    { path: 'dashboard', exact: true },
    { path: 'static', exact: false },
  ];

  return filterRoutes([
    {
      type: 'nav',
      routes: [
        {
          type: 'application',
          name: '@peakwork/peakwork-set-navigation',
          props,
        },
      ],
    },
    {
      type: 'main',
      attrs: [
        {
          name: 'style',
          value: 'min-height: calc(100vh - 186px)',
        },
      ],
      routes: [
        {
          type: 'route',
          path: 'account',
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-account',
            },
          ],
        },
        {
          type: 'route',
          path: 'administration',
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-administration',
            },
          ],
          skip: !isAdministrator && !hasSystemAccessPermissions,
        },
        {
          type: 'route',
          path: 'sets',
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-hotel-set',
              props,
            },
          ],
          skip: !isFeatureEnabled(FEATURES_MAPPING.hotelSets),
        },
        {
          type: 'route',
          path: 'consultations',
          exact: true,
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-consultation',
              props,
            },
          ],
          skip: !isFeatureEnabled(FEATURES_MAPPING.consultation),
        },
        {
          type: 'route',
          path: 'app-management',
          exact: true,
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-app-management',
              props,
            },
          ],
        },
        {
          type: 'route',
          path: 'search',
          routes: [
            {
              type: 'route',
              path: 'inspector',
              routes: [
                {
                  type: 'application',
                  name: '@peakwork/peakwork-set-inspector',
                  props,
                },
              ],
            },
            {
              type: 'route',
              path: 'selector',
              exact: true,
              routes: [
                {
                  type: 'application',
                  name: '@peakwork/peakwork-set-selector',
                  props,
                },
              ],
            },
          ],
          skip: !isFeatureEnabled(FEATURES_MAPPING.search),
        },
        {
          type: 'route',
          path: 'insurances',
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-standalone-insurance',
              props,
            },
          ],
          skip: (
            !isGlobalFeatureEnabled(GLOBAL_FEATURES_MAPPING.insuranceStandalone)
            || !isFeatureEnabled(FEATURES_MAPPING.insurance)
          ),
        },
        {
          type: 'route',
          path: 'merlin-mask',
          exact: true,
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-merlin-mask',
              props,
            },
          ],
          envContextList: ['sabreSet'],
          skip: !isFeatureEnabled(FEATURES_MAPPING.merlin),
        },
        {
          type: 'route',
          path: 'order-management',
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-order-management',
              props,
            },
          ],
          envContextList: ['sabreSet'],
          skip: !isFeatureEnabled(FEATURES_MAPPING.booking),
        },
        {
          type: 'route',
          path: 'forms',
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-prrl-forms',
              props,
            },
          ],
          envContextList: ['sabreSet'],
          skip: !isFeatureEnabled(FEATURES_MAPPING.forms),
        },
        {
          type: 'route',
          path: 'shopping-cart',
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-shopping-cart',
              props,
            },
          ],
          envContextList: ['sabreSet'],
          skip: !isFeatureEnabled(FEATURES_MAPPING.shoppingCart),
        },
        ...staticContentRoutePaths.map(({ path, exact }) => ({
          type: 'route',
          path,
          exact,
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-dashboard',
              props,
            },
          ],
        })),
        {
          type: 'route',
          default: true,
          routes: [
            {
              type: 'application',
              name: '@peakwork/peakwork-set-account',
              props,
            },
          ],
        },
      ],
    },
    {
      type: 'footer',
      routes: [
        {
          type: 'application',
          name: '@peakwork/peakwork-set-footer',
          props,
        },
      ],
    },
  ]);
}

export function loadApp({ name }) {
  return System.import(name);
}

export default function layout(account) {
  const props = {
    envContext: window.PEAKWORK_SET.envContext,
    isLoggedIn: account.userIsLoggedIn(),
    ...account.getUser(),
  };

  const routes = constructRoutes({
    base: '/',
    disableWarnings: false,
    routes: getRoutes(account, props),
    ...(props.isLoggedIn && {
      redirects: {
        '/': '/dashboard',
      },
    }),
  });

  const applications = constructApplications({ routes, loadApp });
  const layoutEngine = constructLayoutEngine({ routes, applications });
  applications.forEach(registerApplication);
  layoutEngine.activate();

  return { routes, applications, layoutEngine };
}
