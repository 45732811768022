import { addErrorHandler, getAppStatus } from 'single-spa';

export function errorHandler(err) {
  console.error(err);
  console.log(err.appOrParcelName);
  console.log(getAppStatus(err.appOrParcelName));
}

export default function debugging() {
  addErrorHandler(errorHandler);
}
