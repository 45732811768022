import { addToGlobalConfig } from './globalConfig';

const LOGGING_ENABLED = process.env.LOGGING_ENABLED === 'true';
const DEFAULT_LOG_LEVEL = process.env.LOGGING_DEFAULT_LOG_LEVEL ?? 'silent';
let importMapCache = null;
const storeKeyPrefix = 'pal'; // as in peakwork-app-logging
const matchMicroFrontendStoreKey = new RegExp(`^${storeKeyPrefix}`, 'i');

async function loadImportMap() {
  if (!importMapCache) {
    importMapCache = window.importMapOverrides.getCurrentPageMap();
  }
  return importMapCache;
}

/**
 * Returns a list of micro frontends from import map, filtered by regular expression
 * @param {RegExp} [matchingExpression = /^@peakwork\/.+/]
 * @returns {Promise<string[]>}
 */
async function getMicroFrontends(matchingExpression = /^@peakwork\/.+/) {
  const { imports } = await loadImportMap();
  return Object.keys(imports).filter((entry) => matchingExpression.test(entry));
}

/**
 * Set loglevel for *key* in store and in window.PEAKWORK_SET
 * @param {String} key
 * @param {String} logLevel
 */
function setLogLevel(key, logLevel) {
  addToGlobalConfig(key, logLevel);
  window.localStorage.setItem(key, logLevel);
}

/**
 * Set loglevel for all micro frontends
 * @param {String} logLevel
 * @returns {Promise<void>}
 */
async function setLogLevelToMicroFrontends(logLevel = DEFAULT_LOG_LEVEL) {
  const loggingSources = await getMicroFrontends();
  loggingSources.forEach((source) => {
    setLogLevel(`${storeKeyPrefix}${source}`, logLevel);
  });
  setLogLevel(storeKeyPrefix, logLevel);
}

/**
 * Reads loglevel of micro frontends from store and adds these to global config
 * @returns {Promise<void>}
 */
function setLogLevelFromStorage() {
  const microFrontends = Object.entries(window.localStorage).filter(
    ([key]) => matchMicroFrontendStoreKey.test(key),
  );
  microFrontends.forEach(([key, logLevel]) => {
    addToGlobalConfig(key, logLevel);
  });
}

/**
 * @param {Boolean} enabled
 * @returns {null|Promise<void>}
 */
export default function setUpLogging(enabled = LOGGING_ENABLED) {
  if (!enabled) {
    return Promise.resolve();
  }

  window.addEventListener(
    'logging',
    ({
      key,
      trusted,
      newValue,
    }) => {
      // trusted = false means, it is a manual event.
      // Like when a dev changes the log level via console i.e.
      // window.dispatchEvent(
      //  new StorageEvent('logging', { key: '<pal@...>', newValue: '<level>' }),
      // );
      if (!trusted && key === 'pal') {
        return setLogLevelToMicroFrontends(newValue ?? DEFAULT_LOG_LEVEL);
      }
      if (!trusted && matchMicroFrontendStoreKey.test(key)) {
        return setLogLevel(key, newValue ?? DEFAULT_LOG_LEVEL);
      }
      return undefined;
    },
  );

  if (!window.localStorage.getItem(storeKeyPrefix)) {
    return setLogLevelToMicroFrontends();
  }
  return setLogLevelFromStorage();
}
