export default async function defaultLanguage() {
  let langIso = window.localStorage.getItem('language');
  if (!langIso) {
    langIso = 'de';
    window.localStorage.setItem('language', langIso);
    window.dispatchEvent(
      new CustomEvent('language-changed', {
        detail: {
          language: langIso,
        },
      }),
    );
  }
}
