export function addToGlobalConfig(key, value) {
  if (!window.PEAKWORK_SET) {
    window.PEAKWORK_SET = {};
  }

  window.PEAKWORK_SET[key] = value;
}

export function getGlobalConfig(key) {
  return window.PEAKWORK_SET?.[key];
}
