import { navigateToUrl } from 'single-spa';
import defaultLanguage from './defaultLanguageHandler';

/**
 * Initially set language for quasar components and
 * adds a event listener to "language-changed".
 */
export function beforeFirstMount() {
  defaultLanguage();
}

/**
 * Checks for an authorized user. If the user is not authorized,
 * and the requested url does not have the prefix "/public/", the
 * application will redirect the user to the login page.
 */
export function beforeRoutingEvent(account) {
  return (evt) => {
    if (
      (
        evt.detail.newUrl
        && (
          !evt.detail.newUrl.includes('account/login')
          && !evt.detail.newUrl.includes('account/keycloak/handover')
          && !account.userIsLoggedIn()
        )
      )
    ) {
      account.saveLoginRedirectPath(evt.detail.newUrl);
    }

    if (
      (
        evt.detail.newUrl
        && (
          evt.detail.newUrl.includes('account/login')
          || evt.detail.newUrl.startsWith(`${window.location.origin}/public/`)
        )
      )
      || account.userIsLoggedIn()
    ) {
      return;
    }
    navigateToUrl('/account/login');
  };
}

export default function registerEvents(account) {
  window.addEventListener('single-spa:before-first-mount', beforeFirstMount);
  window.addEventListener('single-spa:before-routing-event', beforeRoutingEvent(account));
}
