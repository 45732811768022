import { addToGlobalConfig, getGlobalConfig } from './globalConfig';

/**
 * Checks if the application belongs to SabreSet.
 *
 * @returns {Boolean} is sabre or not
 */
function isSabre() {
  return (process.env.SABRE_SET_HOSTNAMES || '')
    .split(',')
    .includes(window.location.hostname);
}

/**
 * Checks for an existing local storage item named peakworkSetEnvContext.
 * If set, it adds a info box to the page.
 *
 * @returns {String|null} the context name
 */
function getContextFromLocalStorage() {
  const context = window.localStorage.getItem('peakworkSetEnvContext');
  if (context !== null) {
    const contextInfoElement = document.createElement('div');
    contextInfoElement.style.padding = '10px';
    contextInfoElement.style.bottom = 0;
    contextInfoElement.style.left = 0;
    contextInfoElement.style.marginLeft = '10px';
    contextInfoElement.style.marginBottom = '10px';
    contextInfoElement.style.backgroundColor = 'salmon';
    contextInfoElement.style.position = 'fixed';
    contextInfoElement.style.zIndex = '10500';
    contextInfoElement.style.borderRadius = '5px';

    const contextTextElement = document.createTextNode(context);
    contextInfoElement.appendChild(contextTextElement);
    document.getElementsByTagName('body')[0].appendChild(contextInfoElement);
  }

  return context;
}

/**
 * Checks and returns the environment context.
 *
 * Checks first for an existing item "peakworkSetEnvContext" in the
 * local storage and returns this if the item exists.
 *
 * Otherwise, it checks the context based on the implemented logic (i.e.
 * checking the hostname).
 *
 * If none of the checks succeed it return the default "peakworkSet".
 *
 * @returns {String} the environment context name
 */
export function setEnvContext() {
  let envContext = 'peakworkSet';

  const localStorageContext = getContextFromLocalStorage();
  if (localStorageContext !== null) {
    envContext = localStorageContext;
  } else if (isSabre()) {
    envContext = 'sabreSet';
  }

  /**
   * Add context to global config object, so we have access to
   * this information in each micro frontend application, library
   * and utility
   */
  addToGlobalConfig('envContext', envContext);
}

export function getEnvContext() {
  return getGlobalConfig('envContext') ?? 'peakworkSet';
}
